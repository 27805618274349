@import "../../assets/theme/vars";

.wrapper_login {
  background: $bg_grey;
  height: 100vh;
  min-height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;

  .content_login {
    background: #fff;
    max-width: 1024px;
    width: 100%;
    height: 80vh;
    min-height: 475px;
    border-radius: 8px;
    box-shadow: -3px -3px 40px rgba(45, 45, 46, 0.15);
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .box_msj {
      background: var(--primary_color);
      color: $white_text;
      height: 100%;
      border-radius: 8px 0 0 8px;
      width: 35%;
      min-width: 200px;
      padding: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      position: relative;
      z-index: 9;

      & > div {
        position: relative;
        z-index: 1;
      }
      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 50%;
        border-radius: 8px;
        background: rgba(0, 0, 0, 0);
        background: -moz-linear-gradient(
          top,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0.15) 100%
        );
        background: -webkit-gradient(
          left top,
          left bottom,
          color-stop(0%, rgba(0, 0, 0, 0)),
          color-stop(100%, rgba(0, 0, 0, 0.15))
        );
        background: -webkit-linear-gradient(
          top,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0.15) 100%
        );
        background: -o-linear-gradient(
          top,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0.15) 100%
        );
        background: -ms-linear-gradient(
          top,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0.15) 100%
        );
        background: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0.15) 100%
        );
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000', GradientType=0 );
      }
      .btn.primary {
        background: transparent;
        color: #ffffff !important;
        border: 2px solid #ffffff;
        margin-bottom: 0 !important;
        padding-top: 8px;
      }
    }
    .box_form {
      width: 65%;
      height: 100%;
      padding: 1rem;
      display: flex;
      justify-content: center;
      text-align: center;
      .box_inputs {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .box_input_icon {
          .ico_show_pass {
            position: absolute;
            width: 27px;
            height: 27px;
            border-radius: 50%;
            top: 9px;
            right: 7px;
            color: #666;
            padding-top: 3px;
            cursor: pointer;

            &:hover {
              background: #dcdcdc;
            }

            .fas {
              font-size: 13px;
            }
          }
        }
      }
      .box_logo_company_login {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 0.25rem;
        // margin-top: 0.5rem;
        img {
          max-height: 110px;
        }
      }
      .box_content_form {
        width: 100%;
        max-width: 350px;
        margin: 0 auto;
        margin-bottom: 2rem;
      }
      .nav_legals {
        text-align: right;
        font-size: 0.9rem;
        a {
          color: #9a9a9a;
          cursor: pointer;
          &:hover {
            color: var(--primary_color);
          }
        }
      }

      .ttl_form {
        color: var(--primary_color);
        margin-bottom: 1rem;
        font-size: 1.75rem;
      }

      .btn_links {
        font-size: 0.95rem;
        cursor: pointer;
      }
      .box_btn_login {
        margin-bottom: 0.8rem;
      }
    }
  }

  .drawer_reset{
    justify-content: flex-start !important;
    .content_div{
      margin-top: 2rem;
      form{
        max-width: 400px;
        margin: 0 auto;
      }
    }
  }
}
